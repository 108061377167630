<!-- =========================================================================================
    File Name: ChartBarChart.vue
    Description: Create bar chart
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template >
  <vx-card class="mt-6">
    <chartjs-component-bar-chart
      :height="200"
      :data="data"
      :options="options"
      v-if="
    dataAvailable"
    ></chartjs-component-bar-chart>
  </vx-card>
</template>

<script>
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Subscriptions",
            // backgroundColor: [
            //   "#3e95cd",
            //   "#8e5ea2",
            //   "#3cba9f",
            //   "#e8c3b9",
            //   "#c45850",
            // ],
            backgroundColor: "#ff4d4d",
            data: this.newChartData,
          },
        ],
      },
      options: {
        legend: { display: true },
        responsive: true,
        maintainAspectRatio: false,
        // title: {
        //   display: true,
        //   text: "Predicted world population (millions) in 2050",
        // },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Months",
              },
            },
          ],
        },
      },
      dataAvailable: false,
    };
  },
  components: {
    ChartjsComponentBarChart,
  },
  methods: {
    ...mapActions("admin", ["fetchChartsDetails"]),
    getDashboardChartDetails() {
      let self = this;
      this.fetchChartsDetails().then((res) => {
        let newChartData = Array(12).fill(0);
        newChartData.forEach((x, i, arr) => {
          const found = res.data.data.find((y) => {
            if (y.month == i + 1) return y;
          });
          if (found) {
            newChartData[found.month - 1] = found.total;
          }
        });
        this.data.datasets[0].data = newChartData;
        this.dataAvailable = true;
      });
    },
  },
  mounted() {
    this.getDashboardChartDetails();
  },
};
</script>
