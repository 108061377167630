<!-- =========================================================================================
    File Name: Charts.vue
    Description: Chartjs (third-party) - Imports page portions
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="space-between">
        <vx-card style="margin:5px; background-color:#00994d; color:white;">
          {{cardDetail.totalUser}}
          <br />Total Users
        </vx-card>
        <vx-card style="margin:5px; background-color:#3333ff; color:white;">
          {{cardDetail.totalCar}}
          <br />Total Cars
        </vx-card>
        <vx-card style="margin:5px; background-color:#004d99; color:white;">
          {{totalListedCar}}
          <br />Listed Cars
        </vx-card>
        <vx-card style="margin:5px; background-color:#ff6666; color:white;">
          {{cardDetail.totalReport}}
          <br />Total Reports
        </vx-card>
      </vs-col>
    </vs-row>
    <vs-row>
    </vs-row>
  </div>
</template>

<script>
import DashboardBarChart from "./DashboardChart.vue";
import { mapActions } from "vuex";
export default {
  components: {
    DashboardBarChart,
  },
  data: () => ({
    cardDetail: {
      totalUser: 0,
      totalCar: 0,
      listedUserCar: 0,
      listedDealerCar: 0,
      totalReport: 0,
    },
  }),
  methods: {
    ...mapActions("admin", ["fetchCardDetails"]),
    ...mapActions(["initToFirstPage"]),
    getDashboardCardDetails() {
      let self = this;
      this.fetchCardDetails().then((res) => {
        this.cardDetail = res.data.data;
      });
    },
  },
  computed: {
    totalListedCar: function() {
      return parseInt(this.cardDetail.listedUserCar) + parseInt(this.cardDetail.listedDealerCar)
    }
  },
  created() {
    this.initToFirstPage({ pageType: "all"});
    this.getDashboardCardDetails();
  },
};
</script>
